const activities =[


    {
        title: 'Mouchtec',
        text: 'Le Mouch\'Tec est le groupe d\'entraînement du Mouch\'PaBa. Il est ouvert à toutes les personnes qui souhaitent découvrir le télémark ou améliorer leur technique. Actuellement, il y a plus de 35 jeunes entre 10 et 20 ans."',
        text2:'Des activités régulières ont lieu toute la saison.',
        text3:'Le Mouch\'Tec est le groupe d\'entraînement du Mouch\'PaBa. Il est ouvert à toutes les personnes qui souhaitent découvrir le télémark ou améliorer leur technique. Actuellement, il y a plus de 20 jeunes entre 10 et 20 ans.',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_5.jpg',
        link:"https://www.mouchtec.ch",
        linkText:"Plus d'informations",
        category:'All',
        date:'2025-05-01',

    },
    {
        title: 'Telemark Worldcup Finals 2025',
        text: 'La clôture de la coupe du monde avec la remise des globes aura lieu à Thyon',
        text2:'Telemark Races',
        text3:'27-30 mars 2025',
        img: process.env.PUBLIC_URL + 'images/fis2024/test3.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:'2025-03-31',

    },
    {
        title: 'MembreSortie_14_01_2023',
        text: 'MembreSortie_14_01_2023_description',
        text2:'MembreSortie_14_01_2023_text2',
        text3:'MembreSortie_14_01_2023_text3',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_1.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:'2023-01-14',

    },



    {
        title: 'TitleAssembly',
        text: 'Assembly_description',
        text2:'Assembly_text2',
        text3:'Assembly_text3',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_2.jpg',
        link:"mailto:amelie.reymond@gmail.com",
        linkText:"Inscriptions",
        category:'All',
        date:'2023-01-14',

    },
    {
        title: 'TitleFriend_1',
        text: 'Friend_1_description',
        text2:'Friend_1_text2',
        text3:'Friend_1_text3',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_3.jpg',
        link:"https://www.mouchtec.ch/inscription-initiation",
        linkText:"Inscriptions",
        category:'All',
        date:'2023-01-21',

    },

    {
        title: 'TitleInitiation',
        text: 'Initiation_description',
        text2:'Initiation_text2',
        text3:'Initiation_text3',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_15.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:'2023-02-05',

    },

    {
        title: 'MembreSortie_18_02_2023',
        text: 'MembreSortie_18_02_2023_description',
        text2:'MembreSortie_18_02_2023_text2',
        text3:'MembreSortie_18_02_2023_text3',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_14.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:"2023-02-18"

    },
    {
        title: 'TeleKidz',
        text: 'Activité ludique pour enfants.',
        text2:'07 janvier 2024',
        text3:'Rdv 13h30 dans l\'aire d\'arrivée',
        img: process.env.PUBLIC_URL + 'images/STC2023/telekids2.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:"2024-01-07"

    },
    {
        title: 'Course FIS et enfants',
        text: 'Course franco-Suisse 2024.',
        text2:'07 janvier 2024',
        text3:'Rdv 08h00 aux Masses',
        img: process.env.PUBLIC_URL + 'images/STC2023/MS1.jpg',
        link:"https://forms.office.com/r/2ijvYeG0wM",
        linkText:"Inscription",
        category:'All',
        date:"2024-01-07"

    },
    {
        title: 'Sortie membre',
        text: 'Sortie ouverte à tous les membres du club.',
        text2:'13 janvier 2024',
        text3:'Rdv 9h30 à la Cambuse, dès 12h au Rôdeur (Igloo 1850)',
        img: process.env.PUBLIC_URL + 'images/MP_Reymond_1.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:"2024-01-13"

    },
    {
        title: 'AG',
        text: 'Assemblée générale du club, suivi d\'une fondue',
        text2:'13 janvier 2024',
        text3:'Rdv 18h00 maison Gauthier Vex',
        img: process.env.PUBLIC_URL + 'images/club/test.jpg',
        link:"mailto:amelie.reymond@gmail.com",
        linkText:"Inscription AG",
        category:'All',
        date:"2024-01-13"

    },
    {
        title: 'Test Télémark et Initiation',
        text: 'Ouvert à tous, le test de télémark est la première occasion de découvrir la discipline télémark.',
        text2:'4 février 2024',
        text3:'Dès 10h00 sur la place de Thyon 2000',
        img: process.env.PUBLIC_URL + 'images/club/sortie.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:"2024-02-04"

    },
    {
        title: 'Sortie membre',
        text: 'Sortie ouverte à tous les membres du club.',
        text2:'10 février 2024',
        text3:'Rdv 9h30 à la Cambuse, dès 12h au Rôdeur (Igloo 1850)',
        img: process.env.PUBLIC_URL + 'images/MP_Reymond_1.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:"2024-02-10"

    },
    {
        title: 'Sortie membre',
        text: 'Sortie ouverte à tous les membres du club.',
        text2:'9 mars 2024',
        text3:'Rdv 9h30 à la Cambuse, dès 12h au Rôdeur (Igloo 1850)',
        img: process.env.PUBLIC_URL + 'images/MP_Reymond_2.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:"2024-03-09"

    },
    {
        title: 'The last of the season',
        text: 'Sortie ouverte à tous les membres du club.',
        text2:'13 avril 2024',
        text3:'Rdv 9h30 à la Cambuse, dès 12h au Rôdeur (Igloo 1850)',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_15.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:"2024-04-13"

    },
    {
        title: 'TitleFriend_2',
        text: 'Friend_2_description',
        text2:'Friend_2_text2',
        text3:'Friend_2_text3',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_9.jpg',
        link:"https://www.mouchtec.ch/inscription-initiation",
        linkText:"Inscriptions",
        category:'All',
        date:"2023-02-04"

    },
    {
        title: 'MembreSortie_18_03_2023',
        text: 'MembreSortie_18_03_2023_description',
        text2:'MembreSortie_18_03_2023_text2',
        text3:'MembreSortie_18_03_2023_text3',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_12.jpg',
        link:"",
        linkText:"",
        category:'All',
        date:"2023-03-18"

    },

    {
        title: 'TitleFriend_3',
        text: 'Friend_3_description',
        text2:'Friend_3_text2',
        text3:'Friend_3_text3',
        img: process.env.PUBLIC_URL + 'images/mouchtec/mouch_11.jpg',
        link:"https://www.mouchtec.ch/inscription-initiation",
        linkText:"Inscriptions",
        category:'All',
        date:"2023-03-11"

    },
    {
        title: 'TitleSwisschamps',
        text: 'Swisschamps_description',
        text2:'Swisschamps_text2',
        text3:'Swisschamps_text3',
        img: process.env.PUBLIC_URL + 'images/romain.jpg',
        link:"/Swisschamps",
        linkText:"Plus d'informations",
        category:'All',
        date:"2023-04-02"

    },














];


export default activities;
