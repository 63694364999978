const diaporamafis=[

    {
        label: 'FIS 2024, picture:Yoan Reymond',
        imgPath: process.env.PUBLIC_URL + 'images/fis2024/test1.jpg',
    },
    {
        label: 'FIS 2024, picture:Yoan Reymond',
        imgPath: process.env.PUBLIC_URL + 'images/fis2024/test2.jpg',
    },
    {
        label: 'FIS 2024, picture:Yoan Reymond',
        imgPath: process.env.PUBLIC_URL + 'images/fis2024/test3.jpg',
    },
    {
        label: 'FIS 2024, picture:Yoan Reymond',
        imgPath: process.env.PUBLIC_URL + 'images/fis2024/test4.jpg',
    },
    {
        label: 'FIS 2024, picture:Yoan Reymond',
        imgPath: process.env.PUBLIC_URL + 'images/fis2024/test5.jpg',
    },

    ]

export default diaporamafis;
