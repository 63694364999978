import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';
import React from 'react';
import  { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";







function TitleHomeCard() {


    return (

        <Card   sx={{ bgcolor:"#bcffff", maxWidth: 345, margin:1 }}>

            <CardMedia
                component="img"
                height="250"
                image={process.env.PUBLIC_URL + 'images/fis2024/test3.jpg'}
                alt="image"
            />
            <CardContent>


                    <Typography paragraph fontSize="1.1rem"  color="text.primary">
                        {"Thyon accueillera les finales de la coupe du monde du 27 au 30 mars 2025 ."}

                    </Typography>
                <Link style={{textDecoration:"none"}} to={"/activites"}>
<Button color="secondary" sx={{ m: 1, width: '25ch' }}
        type="submit"
        variant="contained">
    {"Informations"}
</Button>
                    </Link>




                </CardContent>

        </Card>

    );
}

export default TitleHomeCard;
