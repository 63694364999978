const races =[




    {
        title: 'La course FIS 2024 a eu lieu le 7 janvier 2024 à Thyon',
        text: ' ',
        text2:'',
        text3:'Si vous aimeriez découvrir les photos de la manifestation:',
        text4:'',
        text5:'',
        text6:'',
        text7: 'Nous remercions nos partenaires qui nous ont aidé à réaliser cet évènement. ',
        img: process.env.PUBLIC_URL + 'images/sponsorsfis.png',
        category:'All',

    }


];

export default races;